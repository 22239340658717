var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('van-row',{staticClass:"cont"},[_c('van-col',{attrs:{"span":"24"}},[_c('span',[_vm._v("我们做了什么？")])])],1),_c('van-row',{staticClass:"si"},[_c('van-col',{attrs:{"span":"24"}},[_c('span',{staticClass:"model"},[_vm._v("为了革新心血管疾病管理模式")])])],1),_c('div',{staticClass:"setp"},[_c('div',{staticClass:"dashed"}),_c('van-row',{staticClass:"stepicon"},[_c('van-col',{staticClass:"ishsou",attrs:{"span":"4"}}),_vm._l((_vm.msgdata),function(item,index){return _c('van-col',{key:index,attrs:{"span":"4"}},[(_vm.activited === index)?_c('img',{attrs:{"src":item.img1,"alt":""},on:{"mouseenter":function($event){return _vm.enter(index)},"mouseleave":function($event){return _vm.leave(index)}}}):_c('img',{attrs:{"src":item.img2,"alt":""},on:{"mouseenter":function($event){return _vm.enter(index)},"mouseleave":function($event){return _vm.leave(index)}}}),_c('br'),_c('div',{staticClass:"tt",style:({ color: _vm.activited === index ? '#383EBD' : '#524F4F' })},[_vm._v(" "+_vm._s(item.title)+" ")])])}),_c('van-col',{staticClass:"ishsou",attrs:{"span":"4"}})],2),_c('div',{staticClass:"stepcontent"},_vm._l((_vm.distype),function(item,index){return _c('section',{key:index},[(_vm.activited === index)?_c('div',[_c('van-card',{staticClass:"s-card",scopedSlots:_vm._u([{key:"thumb",fn:function(){return [_c('div',{staticClass:"bg"}),_c('van-image',{staticClass:"asd",style:({
                  height: index === (1 || 2) ? 20.885 + 'vw' : 18.333 + 'vw',
                }),attrs:{"fit":"cover","src":item.img1}})]},proxy:true},{key:"tags",fn:function(){return [_c('div',[_c('dl',{staticClass:"textList"},[_c('dt',{staticClass:"title",staticStyle:{"height":"3.75vw"}},[_vm._v(" "+_vm._s(item.title1)+" ")]),_c('div',{staticClass:"tcont",style:({
                      height: index !== 0 ? 4.375 + 'vw' : 1.917 + 'vw',
                    })},[_vm._v(" "+_vm._s(item.title2)+" ")]),_c('div',{staticClass:"tcont",style:({
                      height: index !== 0 ? 0.48 + 'vw' : 2.917 + 'vw',
                    })},[_vm._v(" "+_vm._s(item.title3)+" ")]),_vm._l((item.list),function(item,index){return _c('dd',{key:index,staticClass:"check"},[_c('a',[_c('img',{staticStyle:{"width":"0.833vw","height":"0.6vw"},attrs:{"src":require("assets/Check.png"),"alt":""}}),_vm._v(" "+_vm._s(item)+" ")])])})],2)])]},proxy:true}],null,true)})],1):_vm._e()])}),0),_c('div',{staticClass:"bottom"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }