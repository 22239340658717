<template>
  <div class="box">
    <div class="zhegai" ref="asd" v-if="tishi"></div>
    <patient-top class="top"></patient-top>
    <patient-mid class="mid"></patient-mid>
    <patient-bottom class="bottom"></patient-bottom>
    <patient-yao class="yao"></patient-yao>
    <patient-insurance class="insurance"></patient-insurance>
    <patient-phone class="phone"></patient-phone>
  </div>
</template>

<script>
import PatientTop from "./homeChild/PatientTop";
import PatientMid from "./homeChild/PatientMid";
import PatientBottom from "./homeChild/PatientBottom";
import PatientYao from "./homeChild/PatientYao";
import PatientInsurance from "./homeChild/PatientInsurance";
import PatientPhone from "./homeChild/PatientPhone";
export default {
  name: "ToPatient",
  data() {
    return {
      tishi: true,
    };
  },
  components: {
    PatientTop,
    PatientMid,
    PatientBottom,
    PatientYao,
    PatientInsurance,
    PatientPhone,
  },
  methods: {
    handleScroll() {
      // 页面滑动的距离
      let scrollTop = document.documentElement.scrollTop;
      if (scrollTop >= this.$refs.asd.offsetHeight) {
        this.moveto = true;
      } else {
        this.moveto = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.mid {
  margin-top: 4.167vw;
}
.zhegai {
  height: 2.917vw;
}
.top {
  margin-top: 6.25vw;
}
.bottom {
  margin-top: 16.167vw;
}
.yao {
  margin-top: 8.333vw;
}
.insurance {
  margin-top: 16.854vw;
}
.phone {
  margin-top: 9.375vw;
}
</style>
