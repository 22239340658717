<template>
  <div>
    <div class="title">
      <div class="tt">
        临床研究
        <div class="bg"></div>
      </div>
      <div class="jieshao">
        瑞尔安心作为领先的数字医疗设备提供商，一直致力于帮助企业及研究者获取更准确、可信的第一手临床研究数据，助力全球心血管系统新药物与新疗法的诞生
      </div>
    </div>
    <van-row style="margin-top: 5.208vw">
      <van-col
        :span="6"
        v-for="(item, index) in research"
        :key="index"
        style="height: 15.625vw"
      >
        <div
          class="imgbox"
          :style="{
            height: 15.625 + 'vw',
            bottom: `${item.bottom}%`,
          }"
        >
          <div
            class="imgstyle"
            :style="{
              width: `${item.imgwidth}vw`,
              height: `${item.imgheight}vw`,
              left: `${item.left}%`,
              transform: `${item.translate}`,
            }"
          >
            <img :src="item.img" alt="" />
          </div>
          <div class="fontstyle">{{ item.title }}</div>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      research: [
        {
          img: require("../../../../assets/patient/2-4.png"),
          title: "高危人群心血管事件预警",
          imgwidth: 7.813,
          imgheight: 7.813,
          left: 50,
          translate: "translateX(-50%)",
        },
        {
          img: require("../../../../assets/research/r1.png"),
          title: "癫痫患者发病监测",
          imgwidth: 9.427,
          imgheight: 9.427,
          left: 50,
          translate: "translateX(-50%)",
          bottom: -20,
        },
        {
          img: require("../../../../assets/research/r2.png"),
          title: "药物副作用/不良事件监测",
          imgwidth: 13.021,
          imgheight: 13.021,
          left: 50,
          translate: "translateX(-50%)",
        },
        {
          img: require("../../../../assets/research/r3.png"),
          title: "房颤患者筛查",
          imgwidth: 6.198,
          imgheight: 6.198,
          left: 50,
          translate: "translateX(-50%)",
          bottom: -20,
        },
        {
          img: require("../../../../assets/research/r4.png"),
          title: "心血管疾病诊断",
          imgwidth: 9.115,
          imgheight: 9.115,
          left: 50,
          translate: "translateX(-50%)",
          bottom: -15,
        },
        {
          img: require("../../../../assets/research/r7.png"),
          title: "睡眠呼吸暂停风险评估",
          imgwidth: 11.458,
          imgheight: 11.458,
          left: 50,
          translate: "translateX(-50%)",
        },
        {
          img: require("../../../../assets/research/r5.png"),
          title: "心肌负荷评估",
          imgwidth: 7.813,
          imgheight: 7.813,
          left: 50,
          translate: "translateX(-50%)",
          bottom: -10,
        },
        {
          img: require("../../../../assets/research/r6.png"),
          title: "心血管药物效果评价",
          imgwidth: 11.458,
          imgheight: 11.458,
          left: 50,
          translate: "translateX(-50%)",
        },
      ],
    };
  },
};
</script>

<style scoped>
.title .tt {
  margin-top: 6.25vw;
  position: relative;
  width: 10vw;
  height: 3.229vw;
  color: #252222;
  font-size: 2.5vw;
  margin-bottom: 1.042vw;
}
.bg {
  position: absolute;
  bottom: 0vw;
  width: 10vw;
  height: 0.729vw;
  opacity: 0.4;
  background-color: #0093b3;
  z-index: -10;
}
.jieshao {
  font-size: 1.25vw;
  font-family: "OPPOSansR";
  line-height: 1.875vw;
}
.shebei {
  margin-top: 4.167vw;
  margin-left: 10.781vw;
  width: 53.438vw;
  height: 26.042vw;
}
img {
  width: 100%;
  height: 100%;
}
.fontstyle {
  margin-top: 0.313vw;
  font-size: 0.833vw;
  line-height: 1.458vw;
  color: #7e7e7e;
  text-align: center;
}
.imgstyle {
  position: relative;
}
.imgbox {
  position: relative;
}
</style>
