<template>
  <div>
    <van-row>
      <van-col span="24" class="title">行业顾问</van-col>
    </van-row>
    <div class="content">
      <div class="asd">
        <van-row type="flex">
          <van-col
            span="8"
            v-for="(item, index) in card"
            :key="index"
            class="move"
          >
            <div class="img">
              <img :src="item.img" alt="" />
            </div>
            <div class="name">{{ item.name }}</div>
            <div class="j">
              <span>{{ item.hos }}</span>
              <span>{{ item.pos }}</span>
            </div>
            <div class="j2">
              <span>{{ item.hos2 }}</span>
              <span>{{ item.pos2 }}</span>
            </div>
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutConsultant",
  data() {
    return {
      card: [
        {
          img: require("../../../../assets/about/doc1.png"),
          name: "Clara Chow",
          hos: "悉尼大学医药健康学院",
          pos: "教授",
          hos2: "澳大利亚和新西兰心脏学会",
          pos2: "主席",
        },
        {
          img: require("../../../../assets/about/doc2.png"),
          name: "Ben Freedman",
          hos: "悉尼大学医学院心脏研究所",
          pos: "心血管研究策略副主任",
          hos2: "澳大利亚知名心脏病专家",
          pos2: "",
        },
        {
          img: require("../../../../assets/about/doc3.png"),
          name: "孟琭",
          hos: "东北大学信息学院",
          pos: "教授",
          hos2: "参与多项国内、国际科研项目的研发工作",
          pos2: "",
        },
      ],
    };
  },
  mounted() {
    // 先获取全部需要过渡效果的元素
    this.fadeInElements = Array.from(document.getElementsByClassName("move"));
    // 监听鼠标滚动事件
    document.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 循环判断是否要触发过渡
    handleScroll() {
      for (var i = 0; i < this.fadeInElements.length; i++) {
        var elem = this.fadeInElements[i];
        if (this.isElemVisible(elem)) {
          elem.style.opacity = "1";
          elem.style.transform = "scale(1)";
          this.fadeInElements.splice(i, 1); // 只让它运行一次
        }
      }
    },
    // 判断元素距离窗口的位置
    isElemVisible(el) {
      var rect = el.getBoundingClientRect();
      var elemTop = rect.top + 200; // 200 = buffer
      var elemBottom = rect.bottom;
      return elemTop < window.innerHeight && elemBottom >= 0;
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.move {
  height: 26.042vw;
  margin-bottom: 2.604vw;
  opacity: 0;
  transition: 0.3s all ease-out;
  transform: scale(0.8);
  box-sizing: border-box;
  padding: 20px;
  display: inline-block;
}
.asd {
  margin-left: 3vw;
}
.title {
  font-size: 2.5vw;
  margin: 0 auto;
  text-align: center;
}
.content {
  /* margin-left: 12.5vw; */
  margin-right: 12.5vw;
  text-align: center;
  margin-top: 6.719vw;
  margin-left: 7.917vw;
}
.van-col--8 {
  width: 17.135vw;
  height: 25.625vw;
}
.img {
  width: 14.583vw;
  height: 14.063vw;
  margin: 0 auto;
}
.img img {
  width: 100%;
  height: 100%;
}
.name {
  margin: 0 auto;
  font-size: 1.25vw;
  margin-top: 1.042vw;
}
.van-row {
  margin-left: 9.01vw;
  margin-right: 9.01vw;
}
.van-col--8 {
  margin-left: 1.823vw;
}

.j {
  font-size: 0.833vw;
  line-height: 1.458vw;
  margin-top: 0.833vw;
  color: #7e7e7e;
  font-family: "OPPOSansR";
}
.j span {
  margin-right: 0.833vw;
}
.j2 span {
  /* margin-right: 0.833vw; */
}
.j2 {
  font-size: 0.833vw;
  line-height: 1.458vw;
  color: #7e7e7e;
  margin-top: 0.625vw;
  font-family: "OPPOSansR";
}
.asd .van-row {
  justify-content: center;
}
</style>
