<template>
  <div class="box">
    <van-row class="cont">
      <van-col span="24"> 慢病药品保障计划</van-col>
    </van-row>
    <van-row class="touse">
      <van-col span="24">
        <span>好药不贵，吃得起</span>
      </van-col>
    </van-row>
    <div class="midbox">
      <div class="logo">
        <img src="~assets/p1.png" alt="" />
      </div>
      <div class="jdh">
        <p>慢病药物3折购，会员购药全场三折</p>
        <span
          >会员商城精心筛选大厂品牌药物，覆盖15个科室、258种常见疾病的药物需求，质量有保障，价格更低廉，全场药物免费包邮。</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.cont {
  font-size: 2.5vw;
  text-align: center;
  color: #252222;
}
.cont span {
  color: #0093b3;
}
.touse {
  font-size: 0.833vw;
  text-align: center;
  margin-top: 0.625vw;
  font-family: "OPPOSansR";
  color: #524f4f;
}
.midbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3.802vw;
}
.logo {
  width: 29.635vw;
  height: 19.115vw;
}
.x {
  margin: 0vw 3.49vw;
  width: 2.5vw;
  height: 2.5vw;
}
.jdh {
  width: 32.125vw;
  /* height: 3.802vw; */
}
.jdh p {
  font-size: 1.875vw;
  color: #252222;
  font-weight: 400;
}
.jdh span {
  font-size: 0.833vw;
  color: #7E7E7E;
  font-weight: 400;
}
img {
  width: 100%;
  height: 100%;
}
</style>
