<template>
  <div class="pmanage">
    <van-row>
      <van-col :span="24" class="ptitle"> 多平台管理 </van-col>
      <van-col :span="24" class="pjs"> 使用多个平台对患者进行管理 </van-col>
    </van-row>
    <van-row type="flex" justify="center" :gutter="40">
      <van-col
        v-for="(item, index) in distype"
        :key="index"
        @mouseenter.native="mouseenter(index)"
        @mouseleave.native="mouseLeave"
      >
        <div class="zhegaimodel" v-if="index === 0 && frist">
          <a class="jigou1" @click="tohosi()">医疗机构登录</a>
        </div>
        <div class="zhegaimodel" v-if="index === 1 && second">
          <div class="jigou">
            <img src="~assets/toperson/down.svg" />
          </div>
        </div>
        <div class="cardmodel">
          <div class="imgsize">
            <img :src="item.img1" alt="" />
          </div>
          <div class="titlemodel">
            <van-row type="flex">
              <van-col span="24" class="titletop">
                {{ item.title1 }}
              </van-col>
              <van-col span="24" class="titlebottom">
                {{ item.title2 }}
              </van-col>
            </van-row>
          </div>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "PersonManage",
  data() {
    return {
      frist: false,
      second: false,
      distype: [
        {
          img1: require("../../../../assets/toperson/s1.png"),
          title1: "适用于机构",
          title2:
            "利用安心患者管理门户 对患者进行管理和不间断随访干预，支持本地分析患者心电数据或上传后由瑞尔安心健康中心的资深心电诊断医师分析并出具报告。",
        },
        {
          img1: require("../../../../assets/toperson/s2.png"),
          title1: "适用于个人医师",
          title2:
            "下载并注册瑞尔良医APP，在线管理患者  调整医嘱，远程随访、实时干预。",
        },
      ],
    };
  },
  methods: {
    tohosi() {
      window.open("http://ecgpc.irealcare.com/");
    },
    mouseenter(e) {
      if (e === 0) {
        this.frist = true;
      } else {
        this.frist = false;
      }
      if (e === 1) {
        this.second = true;
      } else {
        this.second = false;
      }
    },
    mouseLeave() {
      this.frist = false;
      this.second = false;
    },
  },
};
</script>

<style scoped>
@import "~assets/css/vantimg.css";
.zong {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-top: 5.208vw;
}
.pmanage .van-row {
  text-align: center;
}
.ptitle {
  color: #252222;
  font-size: 2.5vw;
}
.pjs {
  color: #524f4f;
  font-size: 0.833vw;
  font-family: "OPPOSansR";
}
.cardmodel {
  margin-top: 5.208vw;
  width: 25.677vw;
  height: 40.521vw;
  border-radius: 0.625vw;
  box-shadow: 0px 20px 40px rgba(3, 0, 77, 0.04);
  /* background-color: rebeccapurple; */
}
.imgsize {
  width: 25.677vw;
  height: 26.042vw;
}
.imgsize img {
  width: 100%;
  height: 100%;
  border-radius: 0.625vw 0.625vw 0 0;
}
.titletop {
  text-align: left;
  color: #252222;
  font-size: 1.458vw;
  line-height: 2.188vw;
  margin-top: 2.5vw;
  margin-left: 2.083vw;
}
.titlebottom {
  text-align: left;
  color: #7e7e7e;
  font-size: 1.042vw;
  line-height: 1.563vw;
  margin: 0.938vw 2.083vw 0 2.083vw;
  font-family: "OPPOSansR";
}
.zhegaimodel {
  margin-top: 5.208vw;
  width: 25.677vw;
  height: 40.521vw;
  border-radius: 0.625vw;
  background-color: rgba(0, 0, 0, 0.5);
  /* opacity: 0.5; */
  position: absolute;
}
.jigou1 {
  color: #383ebd;
  border-radius: 1.094vw;
  font-size: 1.042vw;
  padding: 6px 0.521vw;
  border: 1px solid #383ebd;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  /* width: 9.948vw;
  height: 9.948vw; */
  transform: translate(-50%, -50%);
  cursor: pointer;
  opacity: 1;
  z-index: 99;
}
.jigou img {
}
.jigou {
  color: #383ebd;
  border-radius: 1.094vw;
  font-size: 1.042vw;
  padding: 6px 0.521vw;
  border: 0.26vw solid #a4a0a0;
  /* border-radius: 1.094vw; */
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 9.948vw;
  height: 9.948vw;
  transform: translate(-50%, -50%);
  cursor: pointer;
  opacity: 1;
  z-index: 99;
}
.jigou img {
  width: 100%;
  height: 100%;
}
</style>
