<template>
  <div class="box">
    <how-to-active :msg="msg" :touse="'touse'" class="how"></how-to-active>
  </div>
</template>

<script>
import HowToActive from "components/content/howto/HowToActive";
export default {
  name: "PatientMid",
  components: {
    HowToActive,
  },
  data() {
    return {
      msg: {
        title: "如何使用",
        todo: "请按照以下步骤，正确佩戴使用",
        msgdata: [
          {
            msg: "找到佩戴位置，清洁皮肤并正确佩戴Anxin动态心电记录仪",
            local: "找到位置",
            step: "步骤一",
            activeimg: require("../../../../assets/activeImg/1.gif"),
            imgbg: require("../../../../assets/activeImg/1bg.png"),
          },
          {
            msg: "利用手机连接设备，选择记录时间，开启检测（最长可选7天）",
            local: "连接设备、记录时间",
            step: "步骤二",
            activeimg: require("../../../../assets/activeImg/2.gif"),
            imgbg: require("../../../../assets/activeImg/2bg.png"),
          },
          {
            msg: "监测结束后连接手机，上传心电数据",
            local: "上传数据",
            step: "步骤三",
            activeimg: require("../../../../assets/activeImg/3.gif"),
            imgbg: require("../../../../assets/activeImg/3bg.png"),
          },
          {
            msg: "获取分析报告，并与医生在线沟通结果",
            local: "获取报告",
            step: "步骤四",
            activeimg: require("../../../../assets/activeImg/4.gif"),
            imgbg: require("../../../../assets/activeImg/4bg.png"),
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.how {
  margin-top: 6.25vw;
  margin-bottom: 6.25vw;
}
</style>
