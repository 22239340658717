<template>
  <div class="box">
    <van-row class="cont">
      <van-col span="24">
        <span>{{ msg.title }}</span>
      </van-col>
    </van-row>
    <van-row class="touse">
      <van-col span="24">
        <span>{{ msg.todo }}</span>
      </van-col>
    </van-row>
    <van-row class="cardchoose">
      <van-col
        span="6"
        v-for="(item, index) in msg.msgdata"
        :key="index"
        offset="1"
      >
        <div class="iig" @mouseenter="enter(index)" @mouseleave="leave(index)">
          <img src="~assets/dian2.svg" alt="" v-if="activited === index" />
          <img src="~assets/dian1.svg" alt="" v-else /><br />
          <div class="tt">
            <div class="ttitle">{{ item.msg }}</div>
          </div>
          <div
            class="local"
            :style="{
              'margin-top': touse === 'touse' ? 0.365 + 'vw' : 3.021 + 'vw',
            }"
          >
            {{ item.local }}
          </div>
          <div class="local" style="margin-bottom: 0.521vw">
            {{ item.name }}
          </div>
          <div class="step">
            {{ item.step }}
          </div>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "HomeSay",
  data() {
    return {
      activited: 0,
    };
  },
  props: {
    msg: {
      typeof: Array,
    },
    touse: {
      typeof: String,
    },
  },
  methods: {
    enter(a) {
      // console.log(a);
      this.activited = a;
    },
    leave() {
      this.activited = "";
    },
  },
  mounted() {
    // console.log(this.msg);
  },
};
</script>

<style scoped>
.cont {
  text-align: center;
  font-size: 2.5vw;
  color: #252222;
}
.touse {
  color: #524f4f;
  text-align: center;
  font-size: 0.833vw;
  margin-top: 0.625vw;
  font-family: "OPPOSansR";
}
.cardchoose {
  margin-top: 6.25vw;
  margin-left: 11.979vw;
  margin-right: 11.979vw;
  margin-bottom: 4.167vw;
}
.van-col--6 {
  width: 24%;
  text-align: center;
  border: 0.052vw solid #ffffff;
  border-radius: 0.781vw;
  position: relative;
}
.van-col--6:hover {
  box-shadow: 0 2px 16px 0 rgb(0 0 0 / 7%);
}
.van-col--offset-1 {
  margin-left: 0.5%;
  /* height: 15.677vw; */
}
.cardchoose img {
  width: 4.167vw;
  height: 4.167vw;
  margin-top: -2.083vw;
}
.cardchoose .tt {
  font-size: 0.833vw;
  color: #7e7e7e;
  width: 16.146vw;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 5.885vw;
  margin-left: 1.042vw;
  margin-right: 1.042vw;
  margin-top: 1.042vw;
}
.cardchoose .local {
  color: #252222;
  font-size: 1.042vw;
  margin-top: 3.021vw;
  width: 11.927vw;
  height: 1.563vw;
  margin: 0 auto;
  /* margin-left: 3.125vw;
  margin-right: 3.125vw; */
}
.step {
  margin-left: 6.813vw;
  margin-right: 6.813vw;
  margin-top: 0.417vw;
  color: #383ebd;
  font-size: 0.833vw;
}
.ttitle {
  font-family: "OPPOSansR";
}
</style>
