<template>
  <div>
    <how-logo :coo="coo" :type="'patient'"></how-logo>
  </div>
</template>

<script>
import HowLogo from "components/content/logo/HowLogo";
export default {
  name: "PatientInsurance",
  components: {
    HowLogo,
  },
  data() {
    return {
      coo: {
        title: "瑞尔安心&保险",
        msg: "打通保险全链路，带病投保不是梦",
        cooperation: [
          {
            img: require("../../../../assets/cooperation/soo6.png"),
          },
          {
            img: require("../../../../assets/cooperation/soo3.png"),
          },
          {
            img: require("../../../../assets/cooperation/soo4.png"),
          },
          // {
          //   img: require("../../../../assets/cooperation/soo7.png"),
          // },
          // {
          //   img: require("../../../../assets/cooperation/soo4.png"),
          // },
          // {
          //   img: require("../../../../assets/cooperation/soo5.png"),
          // },
          // {
          //   img: require("../../../../assets/cooperation/soo8.png"),
          // },
          // {
          //   img: require("../../../../assets/cooperation/soo9.png"),
          // },
        ],
      },
    };
  },
};
</script>

<style></style>
