<template>
  <div>
    <van-row>
      <van-col span="24" class="title">{{ coo.title }}</van-col>
    </van-row>
    <van-row>
      <van-col span="24" class="msg">{{ coo.msg }}</van-col>
    </van-row>
    <div class="lo">
      <van-row type="flex">
        <van-col
          :span="4"
          v-for="(item, index) in coo.cooperation"
          :key="index"
          style="text-align: center"
          :style="{ width: type === 'patient' ? 20 + '%' : 28 + '%' }"
        >
          <div class="img">
            <img :src="item.img" alt="" />
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutCooperation",
  props: {
    coo: {
      typeof: Array,
    },
    type: {
      typeof: String,
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 2.5vw;
  margin: 0 auto;
  text-align: center;
}
.lo {
  margin-top: 4.948vw;
  margin-left: 12.5vw;
  margin-right: 12.5vw;
}
.asd {
  display: flex;
  justify-content: center;
  align-items: center;
}
.msg {
  font-size: 0.833vw;
  color: #524f4f;
  text-align: center;
  font-family: "OPPOSansR";
}
.img {
  width: 12.5vw;
  height: 10.156vw;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  /* margin-left: 3.125vw; */
}
.img img {
  width: 100%;
  height: 100%;
}
.lo .van-row {
  justify-content: center;
}
</style>
