<template>
  <div>
    <home-navigation class="nav"></home-navigation>
    <home-banner></home-banner>
    <home-content class="content"></home-content>
    <home-say class="say"></home-say>
    <home-people class="people"></home-people>
  </div>
</template>

<script>
import HomeNavigation from "./homeChild/HomeNavigation";
import HomeBanner from "./homeChild/HomeBanner";
import HomeContent from "./homeChild/HomeContent";
import HomeSay from "./homeChild/HomeSay";
import HomePeople from "./homeChild/HomePeople";

export default {
  components: {
    HomeBanner,
    HomeContent,
    HomeSay,
    HomePeople,
    HomeNavigation,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.content {
  margin-top: 7.292vw;
}
.say {
  margin-top: 3.125vw;
}
.people {
  margin-top: 15.417vw;
}
.nav {
  z-index: 99;
}
</style>
