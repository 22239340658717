<template>
  <div class="box">
    <div class="topbox">
      <div class="leftbox">
        <div class="content">
          <span class="title"
            >我是患者或家属
            <div class="bgcolor"></div>
          </span>
          <div class="neirong">
            了解Anxin动态心电记录仪如何帮助心血管疾病患者及高风险人群更好的应对疾病
          </div>
          <van-button type="info" @click="topatient()">了解更多</van-button>
        </div>
      </div>
      <div class="rightbox">
        <img src="~assets/h1.png" alt="" />
      </div>
    </div>
    <div class="bottombox">
      <div class="leftbox">
        <div class="content">
          <span class="title"
            >我是专业人员
            <div class="zbgcolor"></div>
          </span>
          <div class="neirong">
            了解安心服务的模式以及其为医疗与健康行业带来的革命性变化
          </div>
          <van-button type="info" @click="toPerson()">了解更多</van-button>
        </div>
      </div>
      <div class="rightbox">
        <img src="~assets/z1.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    topatient() {
      this.$router.push({ path: "/topatient" });
    },
    toPerson() {
      this.$router.push({ path: "/toperson" });
    },
  },
};
</script>

<style scoped>
.box {
  height: 74.01vw;
  margin: 0vw 12.5vw;
}
.bottombox {
  display: flex;
}
.topbox {
  display: flex;
  padding-bottom: 6.25vw;
  border-bottom: 1px solid #e4e4e4;
}
.rightbox img {
  width: 38.385vw;
  height: 26.302vw;
}
.leftbox {
  flex: 1;
}
.leftbox .title {
  position: relative;
  font-size: 2.5vw;
  color: #252222;
  z-index: 2;
}
.leftbox .bgcolor {
  position: absolute;
  background-color: #0093b3;
  width: 12.5vw;
  height: 0.729vw;
  right: 0;
  bottom: 0.3vw;
  opacity: 0.4;
  z-index: 1;
}
.leftbox .zbgcolor {
  position: absolute;
  background-color: #0093b3;
  width: 10vw;
  height: 0.729vw;
  right: 0;
  bottom: 0.3vw;
  opacity: 0.4;
  z-index: 1;
}
.content {
  margin-left: 6.406vw;
}
.neirong {
  width: 27.188vw;
  height: 3.125vw;
  font-size: 1.042vw;
  line-height: 1.563vw;
  color: #7e7e7e;
  margin-top: 2.083vw;
  font-family: "OPPOSansR";
}
.van-button {
  margin-top: 9.948vw;
  width: 10.417vw;
  height: 3.125vw;
  border-radius: 0.521vw;
  margin-bottom: 7.813vw;
}
.van-button--normal {
  font-size: 1.042vw !important;
}
.bottombox .rightbox {
  margin-top: 4.167vw;
}
.bottombox .leftbox {
  margin-top: 5.99vw;
}
.van-button--info {
  color: #383ebd;
  font-size: 0.833vw;
  background-color: #383ebd;
}
.van-button__text {
  color: #ffffff;
}
</style>
