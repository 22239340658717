<template>
  <div class="box">
    <div class="leftbox">
      <div class="title">
        <div class="tt">
          管理团队
          <div class="bg"></div>
        </div>
        <div class="botbox">
          <div
            class="peoplenav"
            v-for="(item, index) in people"
            :key="index"
            @mouseenter="enter(index)"
            @mouseleave="leave(index)"
          >
            <div
              class="navItem"
              :style="{ opacity: currentIndex === index ? '1' : '0.4' }"
            >
              <div class="navImg">
                <div class="Img">
                  <img :src="item.img" alt="" />
                </div>
              </div>
              <div class="rcont">
                <div class="name">{{ item.name }}</div>
                <div class="position">{{ item.position }}</div>
                <div class="jieshao">{{ item.jieshao }}</div>
              </div>
              <div
                class="progress"
                style="transform: translate3d(0px, 0px, 0px) scale(0.9, 1)"
                :style="changeprogress(index)"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rightbox">
      <div class="img">
        <div class="bgimg"></div>
        <img src="~assets/about/person1.png" v-if="currentIndex === 0" alt="" />
        <img src="~assets/about/p2.png" v-if="currentIndex === 1" alt="" />
        <img src="~assets/about/p3.png" v-if="currentIndex === 2" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeMid",
  data() {
    return {
      currentIndex: 0,
      nowprogress: 0,
      time: 0,
      people: [
        {
          img: require("../../../../assets/about/person1.png"),
          name: "宋阳",
          position: "首席执行官",
          jieshao:
            "宋阳先生是瑞尔安心的创始人和首席执行官，为公司的所有业务职能及资源合作提供战略指导。",
        },
        {
          img: require("../../../../assets/about/p2.png"),
          name: "林子怀",
          position: "首席技术官",
          jieshao:
            "林子怀先生是瑞尔安心的联合创始人、海外研究院院长兼首席技术官，负责指导公司的硬件产品研发及技术革新。",
        },
        {
          img: require("../../../../assets/about/p3.png"),
          name: "Branka Vucetic",
          position: "董事",
          jieshao: "Branka Vucetic院士是瑞尔安心的董事。",
        },
      ],
    };
  },
  methods: {
    changeprogress(index) {
      // console.log(index)
      if (index === this.currentIndex) {
        return {
          transform: `translate3d(0px, 0px, 0px) scale(${this.nowprogress}, 1)`,
        };
      } else {
        return {
          transform: `translate3d(0px, 0px, 0px) scale(0, 1)`,
        };
      }
    },
    enter(index) {
      this.currentIndex = index;
      this.clearTime2();
    },
    leave(index) {
      this.timer(0);
      this.indextimer(index);
    },
    timer(i) {
      this.clearTimeSet = setInterval(() => {
        if (i > 0.9) {
          i = 0;
        }
        i = i + 0.001;
        // console.log(this.activited);
        this.nowprogress = i;
        // console.log(this.nowprogress)
      }, 2);
    },
    indextimer(index) {
      this.clearTimeindex = setInterval(() => {
        if (index === 2) {
          // this.clearTime();
          index = -1;
          // this.timer();
        }
        index++;
        this.currentIndex = index;
        this.clearTime();
        this.timer(0);
      }, 3680);
    },
    clearTime() {
      clearInterval(this.clearTimeSet);
      // clearInterval(this.clearTimeindex);
      this.clearTimeSet = null;
      // this.clearTimeindex = null;
    },
    clearTime2() {
      clearInterval(this.clearTimeSet);
      clearInterval(this.clearTimeindex);
      this.clearTimeSet = null;
      this.clearTimeindex = null;
    },
  },
  mounted() {
    this.timer(0);
    this.indextimer(0);
  },
};
</script>

<style scoped>
.box {
  margin-left: 12.5vw;
  margin-right: 12.5vw;
  display: flex;
}
.leftbox {
  margin-left: 6.25vw;
  margin-bottom: 9.375vw;
}
.title .tt {
  position: relative;
  width: 10vw;
  height: 3.229vw;
  color: #252222;
  font-size: 2.5vw;
  margin-bottom: 9.375vw;
}
.bg {
  position: absolute;
  bottom: 0vw;
  width: 10vw;
  height: 0.729vw;
  opacity: 0.4;
  background-color: #0093b3;
  z-index: -10;
}
.peoplenav {
  margin-top: auto;
  margin-bottom: auto;
}
.navItem {
  cursor: pointer;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 0.104vw solid #dadcd4;
  padding: 0.417vw 0;
  opacity: 0.6;
  position: relative;
}
.navImg {
  width: 3.646vw;
  margin: 0 0.313vw 0 0.313vw;
}
.Img {
  position: relative;
  /* width: 100vw; */
  /* padding-bottom: 111.11111%; */
  text-align: left;
  transition: opacity 0.2s;
  transition-delay: 0.3s;
  background-size: cover;
}
.Img img {
  width: 100%;
  height: 100%;
}

.rcont {
  text-transform: uppercase;
  font-size: 0.729vw;
}
.position {
  font-size: 1.042vw;
  margin: 0.26vw 0vw;
  color: #524f4f;
  font-family: "OPPOSansR";
}
.name {
  font-size: 1.25vw;
  color: #252222;
}
.jieshao {
  width: 21.979vw;
  font-family: "OPPOSansR";
  /* height: 2.917vw; */
  font-size: 0.833vw;
  line-height: 1.458vw;
  color: #524f4f;
}
.progress {
  position: absolute;
  height: 0.104vw;
  width: 111.11111%;
  top: -0.104vw;
  /* left: 0; */
  background-color: #383ebd;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}
.rightbox {
  position: relative;
  margin-left: 6.198vw;
}
.rightbox .img {
  width: 31.25vw;
  height: 33.854vw;
}
.rightbox .img .bgimg {
  position: absolute;
  z-index: -1;
  margin-top: 2.604vw;
  width: 15.188vw;
  height: 33.854vw;
  background: url("~assets/about/pbg.png");
  background-size: 17.188vw 31.25vw;
  background-repeat: no-repeat;
}
img {
  width: 100%;
  height: 100%;
}
</style>
