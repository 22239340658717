var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('van-row',[_c('van-col',{staticClass:"ptitle",attrs:{"span":24}},[_vm._v(" 联系瑞尔安心 ")])],1),_c('div',{staticClass:"twbox"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rule}},[_c('div',{staticClass:"tform"},[_c('div',{staticClass:"tbox"},[_c('div',{staticClass:"tname"},[_c('el-form-item',{attrs:{"label":"您的姓名","prop":"userName"}},[_c('el-input',{staticClass:"userName",attrs:{"placeholder":"请输入姓名"},model:{value:(_vm.form.userName),callback:function ($$v) {_vm.$set(_vm.form, "userName", $$v)},expression:"form.userName"}})],1)],1),_c('div',[_c('el-form-item',{attrs:{"label":"联系方式","prop":"phoneOrEmail"}},[_c('el-input',{staticClass:"phoneOrEmail",attrs:{"placeholder":"请输入联系方式"},model:{value:(_vm.form.phoneOrEmail),callback:function ($$v) {_vm.$set(_vm.form, "phoneOrEmail", $$v)},expression:"form.phoneOrEmail"}})],1)],1)]),_c('div',[_c('el-form-item',{staticClass:"fk",attrs:{"label":"反馈内容","prop":"advance"}},[_c('el-input',{staticClass:"pdesc",attrs:{"type":"textarea","rows":4,"placeholder":"请输入反馈内容","height":"10.417vw"},model:{value:(_vm.form.advance),callback:function ($$v) {_vm.$set(_vm.form, "advance", $$v)},expression:"form.advance"}})],1)],1),_c('el-button',{staticClass:"btn",style:({
            'border-color':
              _vm.form.advance != '' &&
              _vm.form.phoneOrEmail != '' &&
              _vm.form.userName != ''
                ? '#c6e2ff'
                : '',
            'background-color':
              _vm.form.advance != '' &&
              _vm.form.phoneOrEmail != '' &&
              _vm.form.userName != ''
                ? '#383EBD'
                : '',
          }),on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v("提交")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }