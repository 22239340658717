<template>
  <div>
    <div class="title">
      <div class="tt">
        安心服务
        <div class="bg"></div>
      </div>
      <div class="jieshao">全周期心脏健康检测管理解决方案</div>
    </div>
    <van-row
      class="reportbox"
      type="flex"
      justify="center"
      align="center"
      gutter="32"
    >
      <van-col :span="14">
        <van-row
          class="fangshi"
          v-for="(item, index) in fangshi"
          :key="index"
          type="flex"
          gutter="12"
        >
          <van-col :span="5" class="itemtitle">
            {{ item.title }}
          </van-col>
          <van-col :span="19">
            {{ item.neirong }}
          </van-col>
        </van-row>
      </van-col>
      <van-col :span="10">
        <div class="imgsize">
          <img src="~assets/toperson/ns1.png" alt="" />
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "PersonService",
  data() {
    return {
      fangshi: [
        {
          title: "诊断：",
          neirong: "对潜在患者进行7*24小时心电监测，获取高质量的诊断参考。",
        },
        {
          title: "治疗：",
          neirong:
            "在采用多种治疗手段积极治疗的同时，对患者心电状况和服药情况进行监测与管理，即时评估治疗效果与患者依从度，随时调整治疗策略，提高治疗收益。",
        },
        {
          title: "康复：",
          neirong:
            "定期监测康复期患者心电健康状况，长期干预生活习惯，预防复发。",
        },
        {
          title: "风险评估：",
          neirong: "对高发人群进行风险评估与早期筛查，降低整体医疗成本。",
        },
      ],
    };
  },
};
</script>

<style scoped>
.title .tt {
  margin-top: 6.25vw;
  position: relative;
  width: 10vw;
  height: 3.229vw;
  color: #252222;
  font-size: 2.5vw;
  margin-bottom: 1.042vw;
}
.bg {
  position: absolute;
  bottom: 0vw;
  width: 10vw;
  height: 0.729vw;
  opacity: 0.4;
  background-color: #0093b3;
  z-index: -10;
}
.jieshao {
  font-size: 1.25vw;
  font-family: "OPPOSansR";
}
.reportbox {
  background: linear-gradient(
      283.65deg,
      #fcfcfc 0%,
      rgba(252, 252, 252, 0) 100%
    ),
    #ffffff;
  height: 31.771vw;
  margin: 0vw 6.25vw;
  border-radius: 1.042vw;
}
img {
  width: 100%;
  height: 100%;
}
.biaoti {
  /* margin-top: 5.25vw; */
  font-size: 1.458vw;
  color: #252222;
  margin-bottom: 1.042vw;
}
.imgsize {
  width: 22.917vw;
  height: 24.427vw;
  margin-top: 2.865vw;
}
.imgsize img {
  width: 100%;
  height: 100%;
}
.js {
  color: #7e7e7e;
  font-size: 1.042vw;
  font-family: "OPPOSansR";
  width: 21.563vw;
}
.neirong {
  margin-top: 6.771vw;
  margin-left: 3.365vw;
}
.fangshi {
  margin-top: 0.833vw;
  /* margin-left: 3.365vw; */
  font-size: 0.833vw;
  color: #524f4f;
  /* width: 26.5vw; */
  line-height: 1.458vw;
}
.item {
  margin-top: 0.833vw;
}
.itemtitle {
  text-align: right;
  color: #383ebd;
}
</style>
