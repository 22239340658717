<template>
  <div class="box">
    <about-top class="topbox"></about-top>
    <about-mid class="midbox"></about-mid>
    <about-consultant class="consultant"></about-consultant>
    <about-cooperation class="cooperation"></about-cooperation>
  </div>
</template>

<script>
import AboutTop from "./homeChild/AboutTop";
import AboutMid from "./homeChild/AboutMid";
import AboutConsultant from "./homeChild/AboutConsultant";
import AboutCooperation from "./homeChild/AboutCooperation";
export default {
  name: "AboutUs",
  components: {
    AboutTop,
    AboutMid,
    AboutConsultant,
    AboutCooperation,
  },
};
</script>

<style socped>
.bannerbox {
  position: fixed;
}
</style>
<style scoped>
.topbox {
  margin-top: 11.25vw;
}
.midbox {
  margin-top: 10.417vw;
}
.consultant {
  margin-top: 4.688vw;
}
.cooperation {
  margin-top: 10.208vw;
  margin-bottom: 12.771vw;
}
</style>
