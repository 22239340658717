<template>
  <div class="tobox">
    <person-top></person-top>
    <person-report class="report fade-in"></person-report>
    <person-service class="service fade-in"></person-service>
    <person-manage class="manage fade-in"></person-manage>
    <person-research class="research fade-in"></person-research>
    <person-ask class="ask"></person-ask>
  </div>
</template>

<script>
import { isElemVisible } from "components/common/common.js";
import PersonTop from "./homeChild/PersonTop";
import PersonReport from "./homeChild/PersonReport";
import PersonService from "./homeChild/PersonService";
import PersonManage from "./homeChild/PersonManage";
import PersonResearch from "./homeChild/PersonResearch";
import PersonAsk from "./homeChild/PersonAsk";
export default {
  components: {
    PersonTop,
    PersonReport,
    PersonService,
    PersonManage,
    PersonResearch,
    PersonAsk,
  },
  mounted() {
    // 先获取全部需要过渡效果的元素
    this.fadeInElements = Array.from(
      document.getElementsByClassName("fade-in")
    );
    // 监听鼠标滚动事件
    document.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 循环判断是否要触发过渡
    handleScroll() {
      for (var i = 0; i < this.fadeInElements.length; i++) {
        var elem = this.fadeInElements[i];
        if (isElemVisible(elem, 200)) {
          elem.style.opacity = "1";
          elem.style.transform = "scale(1)";
          this.fadeInElements.splice(i, 1); // 只让它运行一次
        }
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style socped>
.bannerbox {
  position: fixed;
}
</style>
<style>
.tobox {
  /* width: 100vw; */
  /* height: 99.115vw; */
  margin-left: 12.5vw;
  margin-right: 12.5vw;
}
.report,
.service,
.manage,
.research {
  margin-top: 15.25vw !important;
}
.ask {
  margin-top: 11.25vw !important;
  margin-bottom: 10vw;
}
.fade-in {
  /* height: 26.042vw;*/
  margin-bottom: 2.604vw;
  opacity: 0;
  transition: 1s all ease-out;
  transform: scale(0.8);
  /* box-sizing: border-box;
  padding: 1.042vw; */
  /* display: inline-block; */
}
</style>
