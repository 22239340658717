var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('van-row',{staticStyle:{"margin-top":"5.208vw"}},_vm._l((_vm.research),function(item,index){return _c('van-col',{key:index,staticStyle:{"height":"15.625vw"},attrs:{"span":6}},[_c('div',{staticClass:"imgbox",style:({
          height: 15.625 + 'vw',
          bottom: `${item.bottom}%`,
        })},[_c('div',{staticClass:"imgstyle",style:({
            width: `${item.imgwidth}vw`,
            height: `${item.imgheight}vw`,
            left: `${item.left}%`,
            transform: `${item.translate}`,
          })},[_c('img',{attrs:{"src":item.img,"alt":""}})]),_c('div',{staticClass:"fontstyle"},[_vm._v(_vm._s(item.title))])])])}),1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"tt"},[_vm._v(" 临床研究 "),_c('div',{staticClass:"bg"})]),_c('div',{staticClass:"jieshao"},[_vm._v(" 瑞尔安心作为领先的数字医疗设备提供商，一直致力于帮助企业及研究者获取更准确、可信的第一手临床研究数据，助力全球心血管系统新药物与新疗法的诞生 ")])])
}]

export { render, staticRenderFns }