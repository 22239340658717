<template>
  <div>
    <van-row class="cont">
      <van-col span="24">
        <span>我们做了什么？</span>
      </van-col>
    </van-row>
    <van-row class="si">
      <van-col span="24">
        <span class="model">为了革新心血管疾病管理模式</span>
      </van-col>
    </van-row>
    <div class="setp">
      <div class="dashed"></div>
      <van-row class="stepicon">
        <van-col span="4" class="ishsou"></van-col>
        <van-col span="4" v-for="(item, index) in msgdata" :key="index">
          <img
            :src="item.img1"
            @mouseenter="enter(index)"
            @mouseleave="leave(index)"
            alt=""
            v-if="activited === index"
          />
          <img
            :src="item.img2"
            alt=""
            @mouseenter="enter(index)"
            @mouseleave="leave(index)"
            v-else
          /><br />
          <div
            class="tt"
            :style="{ color: activited === index ? '#383EBD' : '#524F4F' }"
          >
            {{ item.title }}
          </div>
        </van-col>
        <van-col span="4" class="ishsou"></van-col>
      </van-row>
      <div class="stepcontent">
        <section v-for="(item, index) in distype" :key="index">
          <div v-if="activited === index">
            <van-card class="s-card">
              <template #thumb>
                <div class="bg"></div>
                <van-image
                  fit="cover"
                  class="asd"
                  :src="item.img1"
                  :style="{
                    height: index === (1 || 2) ? 20.885 + 'vw' : 18.333 + 'vw',
                  }"
                />
              </template>
              <template #tags>
                <div>
                  <dl class="textList">
                    <dt class="title" style="height: 3.75vw">
                      {{ item.title1 }}
                    </dt>
                    <div
                      class="tcont"
                      :style="{
                        height: index !== 0 ? 4.375 + 'vw' : 1.917 + 'vw',
                      }"
                    >
                      {{ item.title2 }}
                    </div>
                    <div
                      class="tcont"
                      :style="{
                        height: index !== 0 ? 0.48 + 'vw' : 2.917 + 'vw',
                      }"
                    >
                      {{ item.title3 }}
                    </div>
                    <dd
                      class="check"
                      v-for="(item, index) in item.list"
                      :key="index"
                    >
                      <a>
                        <img
                          src="~assets/Check.png"
                          alt=""
                          style="width: 0.833vw; height: 0.6vw"
                        />
                        {{ item }}
                      </a>
                    </dd>
                  </dl>
                </div>
              </template>
            </van-card>
          </div>
        </section>
      </div>
      <div class="bottom"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeContent",
  data() {
    return {
      activited: 1,
      time: true,
      distype: [
        {
          img1: require("../../../../assets/Mask.png"),
          title1: "96.6%＞42%，7天持续心电监测对心率失常发现率的惊人提升",
          title2:
            "传统的多导动态心电记录仪单次佩戴时间为24~48小时，房颤发现率仅为42%",
          title3:
            "irealcare动态心电记录仪采用多种技术创新，使患者单次佩戴时间延长到了7天，使偶发性房颤的发现率提高到了96.6%",
          list: ["高达96.6%的房颤发现率", "长达7天的连续心电监测"],
        },
        {
          img1: require("../../../../assets/Mask2.png"),
          title1: "无感佩戴，正常生活",
          title2: "IPx3级防水透气、低致敏性、无束缚，依从度高达97.5%",
          title3: "",
          list: ["IPx3级防水", "透气、低致敏性、无束缚", "依从度高达97.5%"],
        },
        {
          img1: require("../../../../assets/Mask3.png"),
          title1: "可信的动态心电诊断报告",
          title2:
            "每一份心电诊断报告都由资深心电诊断医师在基于深度学习的人工智能辅助下完成，并经过瑞尔安心健康管理中心的专家组审核，拥有极高的可信度。",
          title3: "",
          list: [
            "资深心电诊断医师",
            "人工智能辅助",
            "专家组审核",
            "极高的可信度",
          ],
        },
        {
          img1: require("../../../../assets/Mask4.png"),
          title1: "全方位的生活干预",
          title2:
            "饮食、运动、作息....依据权威资料，为患者提供全方位的生活干预方案，帮助患者养成有利于康复的健康生活习惯。",
          title3: "",
          list: [
            "权威健康干预方案",
            "个性化生活干预策略",
            "全方位健康习惯养成",
          ],
        },
      ],
      msgdata: [
        {
          img1: require("../../../../assets/step/ta1.svg"),
          img2: require("../../../../assets/step/t1.svg"),
          title: "将房颤发现率由42%提升到96%",
        },
        {
          img1: require("../../../../assets/step/ta2.svg"),
          img2: require("../../../../assets/step/t2.svg"),
          title: "让佩戴者正常生活",
        },
        {
          img1: require("../../../../assets/step/ta3.svg"),
          img2: require("../../../../assets/step/t3.svg"),
          title: "提供一份可信的高价值诊断报告",
        },
        {
          img1: require("../../../../assets/step/ta4.svg"),
          img2: require("../../../../assets/step/t4.svg"),
          title: "根据诊断，为患者提供多方面的生活干预",
        },
      ],
    };
  },
  methods: {
    stepclick(e) {
      this.activited = e;
    },
    enter(a) {
      // console.log(a);
      this.activited = a;
      this.clearTime();
    },
    leave() {
      this.timer();
    },
    timer() {
      var i = 0;
      this.clearTimeSet = setInterval(() => {
        if (i === 3) {
          i = -1;
        }
        i++;
        this.activited = i;
        // console.log(this.activited);
      }, 2000);
    },
    clearTime() {
      clearInterval(this.clearTimeSet);
    },
  },
  mounted() {
    this.timer();
  },
};
</script>

<style scoped>
.cont {
  text-align: center;
  font-size: 2.5vw;
  color: #252222;
}
.si {
  text-align: center;
  font-size: 0.833vw;
  color: #524f4f;
  margin-top: 0.625vw;
}
.setp {
  margin-top: 7.448vw;
  /* border-top: 10px dashed #E4E4E4; */
  background: linear-gradient(
      283.65deg,
      #fafafa 0%,
      rgba(250, 250, 250, 0) 99.43%
    ),
    #ffffff;
  border-radius: 10.417vw 0vw 0vw 0vw;
  margin-bottom: 7.292vw;
  /* height: 943px; */
}
.stepicon {
  text-align: center;
}
.stepicon img {
  width: 5.208vw;
  height: 5.208vw;
  margin-top: -2.604vw;
}
.stepicon .tt {
  width: 8.438vw;
  font-size: 0.833vw;
  margin: 0 auto;
}
.stepcontent {
  margin: 0 auto;
  margin-top: 0.938vw;
  width: 60%;
  height: 60%;
  margin-bottom: 9.375vw;
  border-radius: 1.042vw;
  background-color: #ffffff;
}
.bottom {
  height: 2vw;
}
.van-card {
  background-color: #ffffff;
  margin-top: 4.844vw;
}
.van-card__thumb {
  height: 100%;
  width: 50%;
  /* margin-bottom: 12%; */
}
.title {
  color: #252222;
  font-size: 1.25vw;
  margin-top: 5.104vw;
}
.tcont {
  color: #7e7e7e;
  font-size: 0.833vw;
  letter-spacing: 0.04em;
  margin-top: 5%;
}
.check {
  /* display: flex; */
  /* columns: 2; */
  margin-top: 5%;
}
.check img {
  width: 0.833vw;
  height: 1.458vw;
}
dd a {
  float: left;
  width: 50%;
  margin-top: 2%;
  color: #524f4f;
  font-size: 0.833vw;
}
dd {
  margin-left: -1%;
}
/* .van-card__thumb {
  margin-right: 5%;
} */
.asd {
  /* width: 65%; */
  margin-top: 3.104vw;
  width: 25vw;
  height: 18.333vw;
  float: right;
  background: url("~assets/fgbg.png") no-repeat;
}
.dashed {
  height: 3%;
  width: 50%;
  position: absolute;
  margin-left: 25%;
  margin-top: -0.2%;
  z-index: -1;
  border: 0.3em dashed #fff;
  background: linear-gradient(to right, #ffffff, #e4e4e4);
  /* background-origin: border-box; */
}
.textList,
.model {
  font-family: "OPPOSansR";
}
</style>
