<template>
  <div>
    <how-logo :coo="coo"></how-logo>
  </div>
</template>

<script>
import HowLogo from "components/content/logo/HowLogo";
export default {
  name: "PatientInsurance",
  components: {
    HowLogo,
  },
  data() {
    return {
      coo: {
        title: "战略合作",
        cooperation: [
          {
            img: require("../../../../assets/cooperation/soo1.png"),
          },
          {
            img: require("../../../../assets/cooperation/soo2.png"),
          },
          {
            img: require("../../../../assets/cooperation/soo3.png"),
          },
          {
            img: require("../../../../assets/cooperation/soo6.png"),
          },
          // {
          //   img: require("../../../../assets/cooperation/soo5.png"),
          // },
          {
            img: require("../../../../assets/cooperation/soo4.png"),
          },

          // {
          //   img: require("../../../../assets/cooperation/soo7.png"),
          // },
          // {
          //   img: require("../../../../assets/cooperation/soo8.png"),
          // },
          // {
          //   img: require("../../../../assets/cooperation/soo9.png"),
          // },
        ],
      },
    };
  },
};
</script>

<style></style>
