var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_vm._m(0),_c('br'),_vm._m(1),_vm._m(2),_c('div',{staticClass:"down show"},[_c('div',{staticClass:"apple",style:({
        'border-color': _vm.isShowapple ? '#FFFFFF' : '#a4a0a0',
        color: _vm.isShowapple ? '#FFFFFF' : '#a4a0a0',
      }),on:{"mouseenter":function($event){return _vm.enter('apple')},"mouseleave":function($event){return _vm.leave()}}},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":_vm.isShowapple
              ? require('../../../../assets/wapple.svg')
              : require('../../../../assets/apple.svg')}})]),_c('a',{staticClass:"downname",on:{"click":function($event){return _vm.down('ios')}}},[_vm._v("App Store 下载")])]),_c('div',{staticClass:"android",style:({
        'border-color': _vm.isShowandroid ? '#FFFFFF' : '#a4a0a0',
        color: _vm.isShowandroid ? '#FFFFFF' : '#a4a0a0',
      }),on:{"mouseenter":function($event){return _vm.enter('android')},"mouseleave":function($event){return _vm.leave()}}},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":_vm.isShowandroid
              ? require('../../../../assets/wandroid.svg')
              : require('../../../../assets/android.svg')}})]),_c('a',{staticClass:"downname",on:{"click":function($event){return _vm.down('and')}}},[_vm._v("Android 下载")])]),_c('div',{staticClass:"ewm"},[(_vm.isShow)?_c('div',{staticClass:"gongsi"},[_c('img',{staticClass:"img_border",attrs:{"src":require("assets/gongsi.png"),"alt":""}})]):_vm._e(),_c('div',{staticClass:"img",style:({ 'border-color': _vm.isShow ? '#FFFFFF' : '#a4a0a0' }),on:{"mouseenter":function($event){return _vm.enter('er')},"mouseleave":function($event){return _vm.leave()}}},[_c('img',{staticStyle:{"width":"1.667vw","height":"1.667vw"},attrs:{"src":_vm.isShow
              ? require('../../../../assets/ewm2.svg')
              : require('../../../../assets/ewm.svg'),"alt":""}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"asd move2"},[_c('span',{staticClass:"title"},[_vm._v("在手机上管理你的健康")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"js move2"},[_c('span',[_vm._v(" 下载瑞尔安心APP，购买、使用Anxin动态心电记录仪 随时监测身体状态，精准干预健康问题 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iphone move"},[_c('img',{attrs:{"src":require("assets/iPhone.svg"),"alt":""}})])
}]

export { render, staticRenderFns }