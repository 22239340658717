<template>
  <div v-if="isShow">
    <div class="bannerbox" ref="element">
      <div class="logo" @click="tohome">
        <img src="~assets/logo1.svg" alt="" />
        <!-- <img src="~assets/logo2.svg" alt="" v-else /> -->
      </div>
      <div class="mid">
        <ul class="midcont">
          <li
            v-for="(item, index) in bar"
            :key="index"
            @click="topage(index)"
            :style="{ color: currentIndex === index ? '#8489FF' : '' }"
          >
            <a>{{ item }}</a>
          </li>
        </ul>
      </div>
      <div class="right">
        <a class="login" href="http://ecgpc.irealcare.com/">医疗机构登录</a>
        <div class="img" @click="toshow">
          <img :src="img" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeNavigation",
  data() {
    return {
      // isheight: document.$refs.element.offsetHeight,
      height: document.documentElement.clientHeight,
      isShow: true,
      bar: ["关于我们", "面向专业人员", "面向患者", "首页"],
      img: require("../../../../assets/menu-on.svg"),
      currentIndex: 3,
    };
  },
  // watch: {
  //   "$route.path": function (newVal) {
  //     if (newVal === "/home" || newVal === "/") {
  //       this.currentIndex = 3;
  //     } else if (newVal === "/topatient") {
  //     } else if (newVal === "/toperson") {
  //       this.currentIndex = 1;
  //     } else if (newVal === "/aboutus") {
  //       this.currentIndex = 0;
  //     }
  //   },
  // },
  methods: {
    handleScroll() {
      // 页面滑动的距离
      let scrollTop = document.documentElement.scrollTop;
      // 当页面滑动的距离大于300px时元素显示，否则不显示
      if (scrollTop >= this.height) {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
    },
    topage(index) {
      if (index === 0) {
        this.$router.push({ path: "/aboutus" });
        this.$store.commit("saveIndex", 0);
      }
      if (index === 1) {
        this.$router.push({ path: "/toperson" });
        this.$store.commit("saveIndex", 1);
      }
      if (index === 2) {
        this.$router.push({ path: "/topatient" });
        this.$store.commit("saveIndex", 2);
      }
      if (index === 3) {
        this.$router.push({ path: "/" });
        this.$store.commit("saveIndex", 3);
      }
    },
    tohome() {
      this.$router.push({ path: "/" });
      this.$store.commit("saveIndex", 3);
    },
    toshow() {
      window.location.replace("http://ecgpc.irealcare.com/");
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.bannerbox {
  width: 100vw;
  /* height: 3.385vw; */
  position: fixed;
  z-index: 11;
  display: flex;
  /* z-index: 99; */
}
.bannerbox .logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .bannerbox::after {
  content: "";
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("~assets/bg.png");
  filter: blur(1px);
  opacity: 0.3;
  z-index: -99;
} */
a {
  cursor: pointer;
}
.bannerbox img {
  margin-left: 1.5vw;
  margin-top: 0.729vw;
  margin-bottom: 0.729vw;
  /* width: 6.927vw; */
  height: 1.979vw;
}
.midcont li {
  float: right;
  margin-left: 3.438vw;
  color: #e4e4e4;
  font-size: 0.625vw;
}
.midcont {
  margin-top: 1.146vw;
}
.right .img {
  display: none;
}
.right {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 15.625vw; */
  /* margin-top: 0.833vw; */
  margin-right: 3.646vw;
}
.mid {
  flex: 1;
}
.login {
  font-size: 0.625vw;
  color: #e4e4e4;
  padding: 0.313vw 0.521vw;
  border-radius: 1.042vw;
  border: 0.052vw solid #e4e4e4;
  margin-left: 4.688vw;
}
</style>
