<template>
  <div class="topbox">
    <div class="leftbox">
      <div class="bgimg">
        <div class="peoplecard">
          <img src="~assets/about/card.png" alt="" />
        </div>
        <div class="devices">
          <img src="~assets/about/Devices.png" alt="" />
        </div>
        <div class="heart">
          <img src="~assets/about/heart.png" alt="" />
        </div>
      </div>
    </div>
    <div class="rightbox">
      <span class="title">关于瑞尔安心</span>
      <div class="content">
        <span
          >瑞尔安心是一家专注于慢性病监测与管理的数字化医疗解决方案提供商。自2015年创立以来，我们一直致力于将可穿戴技术、人工智能与医学临床实践深度融合，并赋能医疗机构及医生个体，为临床实践提供及时、准确、全面的高质量临床证据。通过技术革新不断提高临床诊疗效率、降低护理成本、促进医学模式转变。</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.topbox {
  margin-left: 12.5vw;
  margin-right: 12.5vw;
  display: flex;
  justify-content: center;
}
.topbox .leftbox {
  width: 26.042vw;
  height: 28.646vw;
}
.topbox .leftbox .bgimg {
  position: relative;
  width: 26.042vw;
  height: 28.646vw;
  background-image: url("~assets/about/bg.png");
  background-size: 100%;
}
.bgimg .peoplecard {
  position: absolute;
  width: 17.396vw;
  height: 5.208vw;
  left: 40%;
  bottom: 0vw;
  transform: translateY(50%);
  border: 1px solid rgb(241, 238, 238);
  /* box-shadow: rgb(207, 201, 201) 0px 0px 3.521vw; */
  border-radius: 0.721vw;
}
.bgimg .heart {
  position: absolute;
  width: 3.646vw;
  height: 3.646vw;
  right: 0.729vw;
  top: 2.396vw;
  border-radius: 1.823vw;
  box-shadow: rgb(207, 201, 201) 0px 0px 3.521vw;
}
.bgimg .devices {
  position: absolute;
  width: 8.5vw;
  height: 7.813vw;
  transform: translateY(-25%);
  border-radius: 1.042vw;
  box-shadow: rgb(207, 201, 201) 0px 0px 3.521vw;
}
img {
  width: 100%;
  height: 100%;
}
.rightbox {
  margin-left: 5.521vw;
}
.rightbox .title {
  width: 18.75vw;
  height: 3.906vw;
  color: #252222;
  font-size: 3.125vw;
}
.rightbox .content {
  width: 28.125vw;
  font-family: "OPPOSansR";
  color: #252222;
  font-size: 0.833vw;
  margin-top: 7.813vw;
  line-height: 1.458vw;
}
</style>
