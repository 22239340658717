<template>
  <div>
    <div class="banner index-hero" :style="{ height: screenWidth + 'px' }">
      <div class="index-hero__logo">
        <img src="~assets/shebei.png" alt="" />
      </div>
      <div class="title">
        <div class="anname">安心系统</div>
        <div class="r">&reg;</div>
      </div>
      <van-row class="jieshao">
        <van-col span="24">
          <span class="ruierspan">
            安心系统是一套完善的<span class="bbtitle">心脏健康监测管理方案</span
            >，可以为患者提供全周期的心血管健康管理。
          </span>
        </van-col>
      </van-row>
      <van-row class="cont">
        <van-col span="24" v-for="(item, index) in asd" :key="index">
          <span class="bt">{{ item.title }}:</span
          ><span>{{ item.content }}</span>
        </van-col>
      </van-row>
      <div class="arrow"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {
      screenWidth: document.documentElement.clientHeight,
      asd: [
        {
          title: "专业",
          content: "专为患者设计的医疗级可穿戴心电检测记录设备",
        },
        {
          title: "持久",
          content: "长达7天的不间断心电记录",
        },
        {
          title: "舒适",
          content: "低致敏性高分子仿生凝胶电极带来的无感佩戴体验",
        },
        {
          title: "准确",
          content: "专有降噪算法辅助高灵敏度心电传感器，记录高质量ECG数据",
        },
        {
          title: "可信",
          content: "人工智能辅助资深心电诊断医师，提供高精度的心电诊断报告",
        },
      ],
    };
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        that.screenWidth = window.fullHeight;
      })();
    };
  },
  watch: {
    screenWidth(val) {
      console.log(val);
      this.screenWidth = val;
    },
  },
  methods: {
    imageLoad() {
      if (!this.isLoad) {
        // this.$emit('sImageLoad')
        this.isLoad = true;
      }
    },
  },
};
</script>
<style scoped>
.banner {
  background-image: url("~assets/banner.png");
  /* height: 735px; */
}
.index-hero {
  display: block;
  position: relative;
  /* width: 100vw; */
  /* min-height: 100%; */
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition-duration: 0.6s;
  transition-delay: 0ms;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
.index-hero__logo {
  position: absolute;
  top: 0;
  left: 40%;
  bottom: 0;
  right: 0;
  /* margin: auto; */
  margin-top: auto;
  margin-right: auto;
  margin-bottom: auto;
  /* background: url("~assets/q1.png") no-repeat; */
  background-size: 100%;
  z-index: 1;
  /* margin-left: auto; */
  width: 45.156vw;
  height: 31.615vw;
  transition-duration: 1.6s;
  transition-delay: 1s;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
.index-hero__logo img {
  width: 100%;
  height: 100%;
}
.title {
  position: absolute;
  display: flex;
  left: 15%;
  top: 20%;
  z-index: 2;
  color: #ffffff;
  transition-duration: 1.6s;
  transition-delay: 1.6s;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
.title .anname {
  font-size: 3.333vw;
}
.r {
  margin-left: 0.625vw;
  margin-top: 0.533vw;
  font-size: 1.233vw;
}
.jieshao {
  position: absolute;
  left: 15%;
  top: 35%;
  z-index: 2;
  /* width: 30%; */
  right: 2%;
  height: 9%;
  color: #e4e4e4;
  font-family: "OPPOSansR";
  font-size: 1.25vw;
  line-height: 2.25vw;
  transition-duration: 1.6s;
  transition-delay: 1.6s;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
.cont {
  /* background-color: black; */
  position: absolute;
  left: 15%;
  top: 58%;
  z-index: 2;
  font-family: "OPPOSansR";
  /* height: 100%; */
  /* width: 27%; */
  font-size: 0.833vw;
  color: #e4e4e4;
  transition-duration: 1.6s;
  transition-delay: 1.6s;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  margin-top: 0.2%;
}
.van-col--24 {
  margin-top: 0.5em;
}
.cont .bt {
  color: #8489ff;
  margin-right: 0.5em;
}
.ruierspan {
  max-width: 30.365vw;
  display: inline-block;
  overflow-wrap: break-word;
  text-align: left;
}
.jieshao .bbtitle {
  color: #8489ff;
}
.arrow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50px;
  margin: 0 auto;
  width: 20px;
  height: 20px;
  border-left: 1px solid #f7f7f5;
  border-bottom: 1px solid #f7f7f5;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation-name: arrow;
  animation-name: arrow;
  -webkit-animation-duration: 1.6s;
  animation-duration: 1.6s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  transition-duration: 1.6s;
  transition-delay: 2.6s;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
@keyframes arrow {
  0% {
    -webkit-transform: translate3d(0, -6px, 0) rotate(-45deg);
    transform: translate3d(0, -6px, 0) rotate(-45deg);
  }
  50% {
    -webkit-transform: translate3d(0, 6px, 0) rotate(-45deg);
    transform: translate3d(0, 6px, 0) rotate(-45deg);
  }
  100% {
    -webkit-transform: translate3d(0, -6px, 0) rotate(-45deg);
    transform: translate3d(0, -6px, 0) rotate(-45deg);
  }
}
</style>
