<template>
  <div>
    <div class="title">
      <div class="tt">
        安心系统
        <div class="bg"></div>
      </div>
      <div class="jieshao">
        Anxin动态心电记录仪已经过NMPA国家认证，并在临床实践中得到了广大医生的认可。
      </div>
    </div>
    <van-row class="shebei">
      <van-col :span="12">
        <div class="imgsize">
          <img src="~assets/person2.png" alt="" />
        </div>
      </van-col>
      <van-col :span="12">
        <van-row>
          <van-col :span="24">
            <div class="biaoti">Anxin动态心电记录仪</div>
          </van-col>
          <van-col :span="24">
            <div class="js">
              经过NMPA认证的专业医疗器械小巧、舒适、准确、7*24小时不间断监测
            </div>
          </van-col>
          <van-col :span="24" class="sbtp">
            <img src="~assets/toperson/shebei.png" alt="" />
            <div class="mc">（第二代Anxin动态心电记录仪）</div>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.title .tt {
  margin-top: 6.25vw;
  position: relative;
  width: 10vw;
  height: 3.229vw;
  color: #252222;
  font-size: 2.5vw;
  margin-bottom: 1.042vw;
}
.bg {
  position: absolute;
  bottom: 0vw;
  width: 10vw;
  height: 0.729vw;
  opacity: 0.4;
  background-color: #0093b3;
  z-index: -10;
}
.jieshao {
  font-size: 1.25vw;
  font-family: "OPPOSansR";
}
.shebei {
  border-radius: 1vw;
  margin-left: 6.25vw;
  margin-right: 6.25vw;
  margin-top: 3.125vw;
  padding-bottom: 1.458vw;
  /* background: linear-gradient(
      283.65deg,
      #fafafa 0%,
      rgba(250, 250, 250, 0) 99.43%
    ),
    #ffffff; */
}
.biaoti {
  margin-top: 5.25vw;
  font-size: 1.458vw;
  color: #252222;
  margin-bottom: 2.083vw;
}
.js {
  color: #7e7e7e;
  font-size: 1.042vw;
  font-family: "OPPOSansR";
  width: 21.563vw;
}
.sbtp {
  margin-top: 3.167vw;
  margin-left: 2.167vw;
  width: 14.479vw;
  height: 9.323vw;
}
img {
  width: 100%;
  height: 100%;
}
.mc {
  color: #a4a0a0;
  font-size: 0.833vw;
  font-family: "OPPOSansR";
  text-align: center;
}
.imgsize {
  margin-top: 2.865vw;
  width: 20.469vw;
  height: 26.042vw;
}
</style>
