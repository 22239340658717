<template>
  <van-row class="reportbox" type="flex" justify="center">
    <van-col :span="12">
      <van-row class="neirong" type="flex" justify="center">
        <van-col :span="24">
          <div class="biaoti">可信的动态心电诊断报告</div>
        </van-col>
        <van-col :span="24">
          <div class="js">
            每一份心电诊断报告都由资深心电诊断医师在基于深度学习的人工智能辅助下完成，并经过瑞尔安心健康中心的专家组审核，拥有极高的可信度。
          </div>
        </van-col>
      </van-row>
    </van-col>
    <van-col :span="12">
      <div class="imgsize">
        <img src="~assets/record.png" alt="" />
      </div>
    </van-col>
  </van-row>
</template>

<script>
export default {};
</script>

<style scoped>
.reportbox {
  /* background: linear-gradient(
      283.65deg,
      #fcfcfc 0%,
      rgba(252, 252, 252, 0) 100%
    ),
    #ffffff;
  height: 31.771vw;
  margin: 0vw 6.25vw;
  border-radius: 1.042vw; */
}
img {
  width: 100%;
  height: 100%;
}
.biaoti {
  /* margin-top: 5.25vw; */
  font-size: 1.458vw;
  color: #252222;
  margin-bottom: 2.083vw;
}
.imgsize {
  width: 32.813vw;
  /* height: 26.042vw; */
  margin-top: 2.865vw;
}
.js {
  color: #7e7e7e;
  font-size: 1.042vw;
  font-family: "OPPOSansR";
  width: 21.563vw;
  line-height: 1.563vw;
}
.neirong {
  margin-top: 10.625vw;
  margin-left: 3.365vw;
}
</style>
