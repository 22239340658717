<template>
  <div>
    <van-row>
      <van-col :span="24" class="ptitle"> 联系瑞尔安心 </van-col>
      <!-- <van-col :span="24" class="pjs"> 使用多个平台对患者进行管理 </van-col> -->
    </van-row>
    <div class="twbox">
      <el-form ref="form" :model="form" :rules="rule">
        <div class="tform">
          <div class="tbox">
            <div class="tname">
              <el-form-item label="您的姓名" prop="userName">
                <!-- <div class="yname">您的姓名 *</div> -->
                <el-input
                  v-model="form.userName"
                  class="userName"
                  placeholder="请输入姓名"
                ></el-input>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="联系方式" prop="phoneOrEmail">
                <!-- <div class="yphone">联系方式 *</div> -->
                <el-input
                  v-model="form.phoneOrEmail"
                  class="phoneOrEmail"
                  placeholder="请输入联系方式"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div>
            <el-form-item label="反馈内容" prop="advance" class="fk">
              <!-- <div class="yfk">反馈内容 *</div> -->
              <el-input
                type="textarea"
                v-model="form.advance"
                class="pdesc"
                :rows="4"
                placeholder="请输入反馈内容"
                height="10.417vw"
              ></el-input
            ></el-form-item>
          </div>
          <el-button
            @click="onSubmit()"
            class="btn"
            :style="{
              'border-color':
                form.advance != '' &&
                form.phoneOrEmail != '' &&
                form.userName != ''
                  ? '#c6e2ff'
                  : '',
              'background-color':
                form.advance != '' &&
                form.phoneOrEmail != '' &&
                form.userName != ''
                  ? '#383EBD'
                  : '',
            }"
            >提交</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { ques } from "@/network/ques.js";
import { Message } from "element-ui";
export default {
  userName: "PersonAsk",
  data() {
    return {
      form: {
        userName: "",
        phoneOrEmail: "",
        advance: "",
      },
      rule: {
        userName: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
        ],
        phoneOrEmail: [
          { required: true, message: "联系方式不能为空", trigger: "blur" },
        ],
        advance: [
          { required: true, message: "反馈内容不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          ques(this.form);
          Message.success("提交成功");
          this.form.userName = "";
          this.form.phoneOrEmail = "";
          this.form.advance = "";
        }
      });
    },
  },
};
</script>

<style scoped>
@import "~assets/css/vantinput.css";
.twbox {
  width: 50vw;
  /* height: 24.833vw; */
  box-shadow: 0 0.104vw 0.833vw 0 rgb(0 0 0 / 7%);
  border-radius: 0.625vw;
  margin-left: 12.5vw;
  font-size: 0.833vw;
}
::v-deep .el-input__inner,
::v-deep .el-textarea__inner {
  border-radius: 0.208vw !important;
}
.yphone,
.yname,
.yfk {
  margin-bottom: 0.417vw;
}
.twbox .tbox {
  display: flex;
  /* margin-left: 3.333vw; */
  padding-top: 2.5vw;
  /* margin-bottom: 1.563vw; */
}
.twbox .tbox .el-input {
  width: 20.833vw;
  height: 2.917vw;
}
.tname {
  margin-right: 2.083vw;
}
.tform {
  margin-left: 3.333vw;
  margin-right: 3.333vw;
}
.btn {
  margin-top: 2vw;
  width: 12.917vw;
  height: 3.125vw;
  background-color: #e4e4e4;
  color: #ffffff;
  font-size: 0.833vw;
  margin-bottom: 2.342vw;
}
/* .el-button:hover {
  color: #409eff;
  background-color: #ecf5ff;
} */
.el-button {
  border-radius: 0.417vw;
}
.el-form-item {
  margin-bottom: 0;
  margin-top: 1.042vw;
}
.ptitle {
  color: #252222;
  font-size: 2.5vw;
  text-align: center;
  margin-bottom: 7.292vw;
}
</style>
