<template>
  <div class="topbox">
    <div class="title">
      <div class="tt">
        更便捷、更准确的记录心电状况
        <div class="bg"></div>
      </div>
      <div class="jieshao">
        Anxin动态心电记录仪已经过NMPA国家认证，并在临床实践中得到了广大医生的认可。
      </div>
    </div>
    <van-row class="box" v-for="(item, index) in jieshao" :key="index">
      <van-col span="24" class="vtitle">{{ item.title }}</van-col>
      <van-col span="24" class="msg">{{ item.msg }}</van-col>
      <div v-if="index === 0" class="box1">
        <div class="img1 fade-in">
          <img v-lazy="item.img" alt="" />
        </div>
        <div class="img2 fade-in">
          <img v-lazy="item.img1" alt="" />
        </div>
      </div>
      <div v-if="index === 1" class="box2">
        <div class="img1 fade-in">
          <img v-lazy="item.img" alt="" />
        </div>
        <div class="img2 fade-in">
          <div class="imgsize" v-for="(item, index) in item.img1" :key="index">
            <img v-lazy="item" alt="" />
          </div>
        </div>
      </div>
      <div v-if="index === 2" class="box3">
        <div class="img1 fade-in">
          <div class="imgbg">
            <img src="~assets/patient/bg2.png" alt="" />
          </div>
          <img v-lazy="item.img" alt="" />
        </div>
        <div class="img2 fade-in">
          <van-row>
            <van-col
              span="12"
              class="imgsize"
              v-for="(item, index) in item.img1"
              :key="index"
              ><img
                v-lazy="item"
                alt=""
                :style="{
                  'margin-left': index >= 2 ? 5 + 'vw' : 1.823 + 'vw',
                }"
            /></van-col>
          </van-row>
        </div>
      </div>
    </van-row>
  </div>
</template>

<script>
import { isElemVisible } from "components/common/common.js";
export default {
  name: "PatientTop",
  data() {
    return {
      jieshao: [
        {
          title: "随时佩戴，不易脱落，快速获取心电报告，随时把控自身健康状况",
          msg: "您可以在日常生活（工作、休息、沐浴、跑步）中佩戴Anxin动态心电记录仪，无需担心设备脱落、进水等情况。佩戴结束后只需要按照提示上传数据，就可以获得一份全面、可靠、被医生认可的心电报告，让您随时随地了解自身健康状况。",
          img: require("../../../../assets/patient/p1.png"),
          img1: require("../../../../assets/record.png"),
        },
        {
          title: "在线咨询三甲名医，实时监测药物效果，随时调整用药策略",
          msg: "报告看不懂？对疾病有疑惑？别着急，我们的APP上面拥有众多三甲名医入驻。向医生发送报告或提出问题，最快三分钟即可获得权威专家解答。你还可以用APP记录服药情况、不良反应，方便医生随时调整用药策略，精准用药。",
          img: require("../../../../assets/patient/p3.png"),
          img1: [
            require("../../../../assets/patient/p4.png"),
            require("../../../../assets/patient/p5.png"),
          ],
        },
        {
          title: "管饮食、记运动、健康生活触手可及",
          msg: "发现问题不重要，解决问题才重要。基于机器学习和深度神经网络的主动健康干预模型可以根据健康数据和健康行为跟踪，为你提供个性化的全方位生活干预建议，让健康生活触手可及。",
          img: require("../../../../assets/patient/p7.png"),
          img1: [
            require("../../../../assets/patient/p6.png"),
            require("../../../../assets/patient/p8.png"),
            require("../../../../assets/patient/p9.png"),
            require("../../../../assets/patient/p10.png"),
          ],
        },
      ],
    };
  },
  mounted() {
    // 先获取全部需要过渡效果的元素
    this.fadeInElements = Array.from(
      document.getElementsByClassName("fade-in")
    );
    // 监听鼠标滚动事件
    document.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 循环判断是否要触发过渡
    handleScroll() {
      for (var i = 0; i < this.fadeInElements.length; i++) {
        var elem = this.fadeInElements[i];
        if (isElemVisible(elem, 200)) {
          elem.style.opacity = "1";
          elem.style.transform = "scale(1)";
          this.fadeInElements.splice(i, 1); // 只让它运行一次
        }
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.topbox {
  margin-left: 18.906vw;
  margin-right: 18.906vw;
}
.title .tt {
  color: #252222;
  font-size: 2.5vw;
  position: relative;
}
.fade-in {
  height: 26.042vw;
  margin-bottom: 2.604vw;
  opacity: 0;
  transition: 0.3s all ease-out;
  transform: scale(0.8);
  box-sizing: border-box;
  padding-left: 1.042vw;
  display: inline-block;
}
.bg {
  position: absolute;
  bottom: 0vw;
  width: 17.448vw;
  height: 0.729vw;
  opacity: 0.4;
  background-color: #0093b3;
  z-index: -10;
}
.title .jieshao {
  margin-top: 0.625vw;
  color: #524f4f;
  font-family: "OPPOSansR";
  font-size: 0.833vw;
  line-height: 1.458vw;
}
.box {
  margin-top: 5.208vw;
  width: 61.979vw;
  height: 34.479vw;
}
.msg {
  font-size: 0.833vw;
  font-family: "OPPOSansR";
  color: #7e7e7e;
  line-height: 1.458vw;
  margin-top: 1.563vw;
  margin-bottom: 2.083vw;
}
.box1,
.box2,
.box3 {
  display: flex;
  margin-top: 2.083vw;
  width: 53.438vw;
  height: 26.042vw;
}
.box1 .img1 {
  width: 20.469vw;
  height: 26.042vw;
}
.box1 .img2 {
  width: 31.927vw;
  height: 26.042vw;
}
img {
  width: 100%;
  height: 100%;
}
.box2 .img1 {
  width: 31.146vw;
  height: 26.042vw;
}
.box2 .img2 {
  margin-left: 1.823vw;
}
.box2 .img2 .imgsize {
  width: 20.469vw;
  height: 12.135vw;
  margin-bottom: 1.771vw;
}
.box3 .img1 {
  width: 20.469vw;
  height: 26.042vw;
}
.box3 .img2 .imgsize {
  width: 11.073vw;
  height: 10.938vw;
  margin-top: 2.083vw;
  margin-left: 1.823vw;
}
.box3 .img2 {
  width: 32.813vw;
  height: 26.042vw;
}
.imgbg {
  /* width: 65%; */
  position: absolute;
  width: 12.396vw;
  top: 19vw;
  left: -0.88vw;
  height: 7.656vw;
  z-index: -9;
}
.imgbg img {
  width: 100%;
  height: 100%;
}
.vtitle {
  font-size: 1.25vw;
  line-height: 1.875vw;
}
</style>
