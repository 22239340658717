<template>
  <div class="box">
    <div class="asd move2">
      <span class="title">在手机上管理你的健康</span>
    </div>
    <br />
    <div class="js move2">
      <span>
        下载瑞尔安心APP，购买、使用Anxin动态心电记录仪
        随时监测身体状态，精准干预健康问题
      </span>
    </div>
    <div class="iphone move">
      <img src="~assets/iPhone.svg" alt="" />
    </div>
    <div class="down show">
      <div
        class="apple"
        @mouseenter="enter('apple')"
        @mouseleave="leave()"
        :style="{
          'border-color': isShowapple ? '#FFFFFF' : '#a4a0a0',
          color: isShowapple ? '#FFFFFF' : '#a4a0a0',
        }"
      >
        <div class="img">
          <img
            :src="
              isShowapple
                ? require('../../../../assets/wapple.svg')
                : require('../../../../assets/apple.svg')
            "
          />
        </div>
        <a class="downname" @click="down('ios')">App Store 下载</a>
      </div>
      <div
        class="android"
        @mouseenter="enter('android')"
        @mouseleave="leave()"
        :style="{
          'border-color': isShowandroid ? '#FFFFFF' : '#a4a0a0',
          color: isShowandroid ? '#FFFFFF' : '#a4a0a0',
        }"
      >
        <div class="img">
          <img
            :src="
              isShowandroid
                ? require('../../../../assets/wandroid.svg')
                : require('../../../../assets/android.svg')
            "
          />
        </div>
        <a class="downname" @click="down('and')">Android 下载</a>
      </div>
      <div class="ewm">
        <div class="gongsi" v-if="isShow">
          <img class="img_border" src="~assets/gongsi.png" alt="" />
        </div>
        <div
          class="img"
          @mouseenter="enter('er')"
          @mouseleave="leave()"
          :style="{ 'border-color': isShow ? '#FFFFFF' : '#a4a0a0' }"
        >
          <!-- <img src="~assets/ewm2.svg" alt="" v-if="isShow" style="width:100%"/>
          <img src="~assets/ewm.svg" alt="" v-else style="width:100%"/> -->
          <img
            :src="
              isShow
                ? require('../../../../assets/ewm2.svg')
                : require('../../../../assets/ewm.svg')
            "
            alt=""
            style="width: 1.667vw; height: 1.667vw"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isElemVisible } from "components/common/common.js";
export default {
  name: "PatientPhone",
  data() {
    return {
      isShow: false,
      isShowandroid: false,
      isShowapple: false,
    };
  },
  mounted() {
    // 先获取全部需要过渡效果的元素
    this.fadeInElements = Array.from(document.getElementsByClassName("move"));
    this.move2 = Array.from(document.getElementsByClassName("move2"));
    this.show = Array.from(document.getElementsByClassName("show"));
    // 监听鼠标滚动事件
    document.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    down(e) {
      if (e === "ios") {
        console.log("ios");
        window.open(
          "https://apps.apple.com/cn/app/%E7%91%9E%E5%B0%94%E5%AE%89%E5%BF%83/id1192671849"
        );
      } else {
        window.open(
          "http://api.irealcare.com/irealcare/user/downloadUpgrade?path=irealcare_2021_01_21_1615.apk"
        );
      }
    },
    enter(e) {
      if (e === "android") {
        this.isShowandroid = true;
      } else if (e === "apple") {
        this.isShowapple = true;
      } else if (e === "er") {
        this.isShow = true;
      }
    },
    leave() {
      this.isShow = false;
      this.isShowandroid = false;
      this.isShowapple = false;
    },
    // 循环判断是否要触发过渡
    handleScroll() {
      for (var i = 0; i < this.fadeInElements.length; i++) {
        var elem = this.fadeInElements[i];
        if (isElemVisible(elem, 200)) {
          elem.style.opacity = "1";
          elem.style.transform = "translate(-19.792vw, -7.2vw) scale(1.5)";
          this.fadeInElements.splice(i, 1); // 只让它运行一次
        }
      }
      for (var j = 0; j < this.move2.length; j++) {
        var asd = this.move2[j];
        if (isElemVisible(asd, 300)) {
          asd.style.opacity = "1";
          asd.style.transform = "translate(19.792vw, 9vw)";
          this.move2.splice(j, 1); // 只让它运行一次
        }
      }
      for (var z = 0; z < this.show.length; z++) {
        var zz = this.show[j];
        if (isElemVisible(zz, 100)) {
          zz.style.opacity = "1";
          this.show.splice(z, 1); // 只让它运行一次
        }
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.box {
  position: relative;
  /* width: 100vw; */
  height: 52.8vw;
  background: url("~assets/pbg.png");
  background-size: 100%;
  text-align: center;
}
.title {
  color: #ffffff;
  font-size: 2.5vw;
}
.js span {
  font-size: 0.833vw;
  color: #e4e4e4;
  font-family: "OPPOSansR";
  margin-top: 0.729vw;
  line-height: 1.25vw;
  width: 20.521vw;
  height: 2.813vw;
  text-align: center;
  display: inline-block;
}
.js {
  display: inline-block;
}
.asd {
  display: inline-block;
  margin-top: 6.25vw;
}
.iphone {
  position: absolute;
  width: 25.219vw;
  height: 38.958vw;
  margin-left: 36.625vw;
  bottom: -9.6%;
}
img {
  width: 100%;
  height: 100%;
}
.move {
  /* height: 500px; */
  /* margin-left: 150px; */
  /* opacity: 0; */
  transition: 2s all ease-out;
  transform: scale(1);
  /* box-sizing: border-box; */
  /* padding: 20px; */
  /* display: inline-block; */
}
.move2 {
  /* height: 500px; */
  /* margin-left: 150px; */
  /* opacity: 0; */
  transition: 1.5s all ease-out;
  transform: scale(1);
  /* box-sizing: border-box; */
  /* padding: 20px; */
  /* display: inline-block; */
}
.show {
  opacity: 0;
  transition: 1.5s all ease-out;
}
.down {
  display: flex;
  height: 3.646vw;
  margin-top: 25.667vw;
  margin-left: 52.667vw;
}
.apple,
.android {
  display: flex;
  justify-content: center;
  align-items: center;
}
.down img {
  margin-right: 0.208vw;
}
.downname {
  font-size: 20px;
}
.apple,
.android {
  padding: 0.685vw 1.31vw;
  border-radius: 0.521vw;
  border: 2px solid #a4a0a0;
  margin-right: 2.083vw;
}
.ewm .img {
  width: 1.615vw;
  height: 1.615vw;
  padding: 0.833vw;
  border-radius: 0.521vw;
  border: 2px solid #a4a0a0;
}
.gongsi {
  width: 9.948vw;
  height: 9.948vw;
  position: absolute;
  /* left: 82.667vw; */
  /* top: 25.417vw; */
  margin-top: -12vw;
  margin-left: -3.9vw;
}
.img_border {
  border: 0.125rem solid #e4e4e4;
  padding: 0.3125rem;
  background: #fff;
  border-radius: 0.25rem;
}
a {
  cursor: pointer;
}
</style>
